<template>
  <div class="container with-price-box">
    <div>
      <h1>Paleta Ibérica (cochino)</h1>
      <p>
        Esta es la pata delantera del cerdo ibérico. Es más pequeña que la pata trasera (jamón) y tiene una forma
        diferente.
      </p>
      <p>El peso total está entre 4 y 6 kilogramos. Las familias en España suelen tener una pata así en la cocina y la
        consumen
        muy rápidamente.</p>
      <p>
        Como los cerdos se han engordado con bellotas dulces, el jamón tiene un sabor distintivo a nuez que recuerda al
        sabor de las bellotas. Es un pedazo de Dehesa en tu mesa. También combina bien con una copa de vino tinto.
      </p>

      <p>Aquí hay un video que muestra cómo se han criado los cerdos y cómo se hace el jamón:</p>

      <CookieControlledContent>
        <iframe data-cy="youtubeVideo" class="youtube-video" width="460" height="258"
          src="https://www.youtube.com/embed/1xHDWjk90sc?si=QCoQir0t9T8tUcsI" title="Reproductor de video de YouTube"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </CookieControlledContent>

      <picture v-if="!$cookieConsent.accepted()">
        <a href="/img/shop/2023-Ham-Promo-1080.jpg"><img src="@/assets/images/2023-Ham-Promo-250.jpg" /></a>
      </picture>

    </div>
    <PriceBox product="c55eb01b-0d1e-4f9d-9af6-7d16e6678496" />
  </div>
</template>

<script>
import PriceBox from '@/components/PriceBox.vue'
import CookieControlledContent from '@/components/CookieControlledContent.vue'

export default {
  name: "LegOfHam",
  components: {
    PriceBox, CookieControlledContent
  },
}
</script>
